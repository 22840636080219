import React from 'react';
import './post.css';

export default function Post(props) {
  const { title, url, thumbnail } = props;

  return (
    <div className="post">
      <a href={url} target="_blank" rel="noopener noreferrer" className="imageLink">
        <img className="postImage" src={thumbnail} data-src={url} rel="noopener noreferrer" target="_blank" alt="thumbnail"></img>
      </a>
      <a className="title" href={url} rel="noopener noreferrer" target="_blank">{title}</a>
    </div>
  );
}