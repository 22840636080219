
import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAVxArbu4JwaW8kE4tjwwe2cM4sk3_F_Oo",
  authDomain: "savemullapdam.firebaseapp.com",
  projectId: "savemullapdam",
  storageBucket: "savemullapdam.appspot.com",
  messagingSenderId: "131148903944",
  appId: "1:131148903944:web:8b45d005e3d45a29c603bd",
  measurementId: "G-TG6L8M7P37",
  databaseURL: "https://savemullapdam-default-rtdb.firebaseio.com/"
};

const app = initializeApp(firebaseConfig);

const database = getDatabase(app);
const authentication = getAuth(app);
authentication.languageCode = 'en';
export { authentication, database }
