import { FaBars } from 'react-icons/fa'
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
  top: 0;
  display: flex;
  height: 85px;
  width: 100%;
  
  background: #63D471;
  padding: 0.2rem 0.2rem 0.2rem calc((100vw - 350px));
  z-index: 12;
  @media screen and (max-width: 768px) {
    height: 65px;
    padding: 0.2rem 0.2rem 0.2rem calc((100vw - 320px));
  }
`;

export const NavLink = styled(Link)`
  color: #2b625f;
  display: flex;
  align-items: center;
  height: 60%;
  cursor: pointer;
  padding: 0 1rem;
  text-decoration: none;
  border: 1px solid #639ed4;
  border-radius: 10px;
  margin: 0.2rem;
  &.active {
      color: #f8f8f8;;
      border: 1px solid #fff;
      border-radius: 10px;
    }
  @media screen and (max-width: 768px) {
    
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #808080;
  /*
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }*/
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  /* Second Nav */
  /* margin-right: 24px; */
  /* Third Nav */
  /* width: 100vw;
  white-space: nowrap; */
  @media screen and (max-width: 768px) {
    max-height: 350px;
    margin-left: 8px;

  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 12px;
  /* Third Nav */
  /* justify-content: flex-end;
  width: 100vw; */
  @media screen and (max-width: 768px) {
    max-height: 350px;
  }
`;

export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: #808080;
  padding: 10px 22px;
  color: #f0f0f0;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  /* Second Nav */
  margin-left: 12px;
  height: 60%;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #808080;
  }
  @media screen and (max-width: 768px) {
    padding: 3px 22px;
  }
`;

