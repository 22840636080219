import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import 'react-bootstrap/dist/react-bootstrap.min.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { getDatabase, ref, child, get } from "firebase/database";
import { useDispatch } from 'react-redux';
import Home from './pages/home';
import Petition from './pages/petition';
import SignIn from './pages/signin';
import { setUser } from './features/userSlice';
import {setPetition} from './features/petitionSlice';
function App() {
  const dispatch = useDispatch();
  const phValue = window.localStorage.getItem("phno");
  dispatch(setUser(phValue !== null ? phValue :  null));
  const dbRef = ref(getDatabase());
  
  get(child(dbRef, `/users/${phValue}`)).then((snapshot) => {
    if (snapshot.exists()) {
      console.log(snapshot.val());
      dispatch(setPetition(snapshot.val()));
      console.log(snapshot.val());
    } else {
      console.log(phValue +snapshot + "No data available");
    }
  }).catch((error) => {
    console.error(error);
  });

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/petition' element={<Petition />} />
        <Route path='/signin' element={<SignIn />} />
      </Routes>
    </Router>

  );
}

export default App;