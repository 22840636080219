import { createSlice } from "@reduxjs/toolkit";
const userSlice = createSlice({
    name: 'user',
    initialState: { phone: '' },
    reducers: {
        setUser: (state, action) => {
            state.phone = action.payload;
        },
        getUser: (state) => {
            return state.phone;
        }
    }
});
export const { setUser, getUser } = userSlice.actions;
export default userSlice.reducer;