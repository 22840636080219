import { configureStore } from "@reduxjs/toolkit";
import postSlice from "./features/postSlice";
import userSlice from "./features/userSlice";
import petitionSlice from "./features/petitionSlice";
const store = configureStore({
    reducer: {
        posts: postSlice,
        user: userSlice,
        petition: petitionSlice
    }
});
export default store;