import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector , useDispatch} from 'react-redux';
import {
    Nav,
    NavLink,
    Bars,
    NavMenu,
    NavBtn,
    NavBtnLink
} from './NavbarElements';
import {setUser} from '../../features/userSlice';

const NavBar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const phone = useSelector((state) => state.user.phone);
    const handleSignOut = (e) => {
        dispatch(setUser(null));
        window.localStorage.removeItem('phno');
        navigate("/", { replace: true });
    }
    return (
        <>
            <Nav>
                <Bars />
                <NavMenu>
                    <NavLink to='/' activestyle="true">
                        Home
                    </NavLink>
                    <NavBtn>
                        <NavBtnLink to='/petition'>
                            Petition
                        </NavBtnLink>
                    </NavBtn>

                    {phone ?
                        <NavLink onClick={handleSignOut}>
                            Sign Out
                        </NavLink>
                        :
                        <NavLink to='/signin'>
                            Sign In
                </NavLink>
                    }
                </NavMenu>
            </Nav>
        </>
    );
};

export default NavBar;