import { createSlice } from "@reduxjs/toolkit";
const petitionSlice = createSlice({
    name: 'petition',
    initialState: {},
    reducers: {
        setPetition: (state, action) => {
            state.petition = action.payload;
        },
        getPetition: (state) => {
            return state;
        }
    }
});
export const { setPetition, getPetition } = petitionSlice.actions;
export default petitionSlice.reducer;