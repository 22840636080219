import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import { authentication } from '../components/Firebase/firebase';
import { useDispatch } from 'react-redux';
import { setUser } from '../features/userSlice';
import {
  isValidPhoneNumber
} from 'libphonenumber-js'
import countryCode from '../utils/countryCode.json';

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setotp] = useState('');
  const [expandForm, setExpandForm] = useState(false);
  const [phvalid, setphvalid] = useState(true);
  const codeElement = useRef();
  const numberElement = useRef();
  
  const renderErrorMessage = (name) => {
    return (
      name === 'phoneno' && (
        <div className='error'>Please check your number!...</div>
      ))
  }
  const changeHandler = (e) => {
    //setnumber(e);
    if (!phvalid) {
      setphvalid(true);
    }
  }
  const onSignInSubmit = () => {

    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult.confirm(otp).then((result) => {
        const user = result.user;
        window.localStorage.setItem('phno', user.phoneNumber);
        dispatch(setUser(user.phoneNumber));
        navigate(-1);
        // ...
      }).catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
      });
    }
  }

  const genarateRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(authentication, 'recaptcha-container', {
        'size': 'invisible',
        'callback': (response) => {
           onSignInSubmit();
        }, "expired-callback": () => {},
      });
    }
  }
  const requestOTP = (e) => {
    e.preventDefault();
    const phno = codeElement.current.value+numberElement.current.value;
    const isValid = isValidPhoneNumber(phno);

    if (!isValid) {
      setphvalid(false);
    }
    else {
      setExpandForm(true);
      genarateRecaptcha();
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(authentication, phno, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          // ...
        }).catch((error) => {
          // Error; SMS not sent
          console.log(error);
        });
    }
  }

  return (
      <div className='form_page'>
      <div className='signin'>
        <div style={{ display: 'table', width: '135px' }}>
          <p className='title'>Sign In with Phone Number</p>
        </div>
        <div className='input_container'>
          {/* <label>Mobile Number</label> */}
          <select class="country-code" id="countryCode" name="country_code" ref={codeElement}>
            {countryCode.countries.map((country) => <option key={country.code2}>+{country.isd}</option>)}
          </select>
          <input type='text' name='phoneno' ref={numberElement}  onChange={(e) => changeHandler(e.target.value)} required></input>
          {!phvalid && renderErrorMessage('phoneno')}
          <div id="recaptcha-container"></div>
          <button style={{ width: '50%' }} onClick={requestOTP}>Send OTP</button>
        </div>
        {expandForm ? <div className='button_container'>
          <input type='text' style={{ width: '50%' }} placeholder={"Enter your OTP"} onChange={(e) => setotp(e.target.value)} required ></input>
          <input id='sign-in-button' type="submit" onClick={onSignInSubmit} />


        </div> : null}
      </div>

    </div>
  );
};

export default SignIn;