import { createSlice } from "@reduxjs/toolkit";
import list from '../components/Data/post';

const postSlice = createSlice({
    name: 'posts',
    initialState: { mulla: list },
    reducers: {
        getPosts: (state) => {
            return state;
        }
    }
});

export default postSlice.reducer;