import React from 'react';
import './mainList.css';

import { useSelector } from "react-redux";
import Post from '../components/Post/post';

export default function Home() {
  const value = useSelector((state) => {
    return state.posts
});
const values = [...value.mulla]

  return (
    <div className='list_content'>
      {<div className="grid_list">{values.map((item) =>
        <Post key={item.id} {...item}></Post>
      )}</div>}
    </div>
  );
}