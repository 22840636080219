
import React, { useState, useEffect } from 'react';
import { database } from '../components/Firebase/firebase';
import { ref, set }from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './petition.css';

const Petition = () => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const navigate = useNavigate();
  const phone = useSelector((state) => state.user.phone);
  const petition = useSelector((state) =>state.petition.petition);
    
  useEffect(() => { 
    if (!phone) {
      navigate("/signin");
    } else if(petition && phone === petition.phone) {
      setIsSubmitted(true);
    }
  }, [phone, petition]);
  const validationHandler = () => {
    const { name, email } = fields;
    let valid = true, ferrors = {};
    if (name === undefined) {
      valid = false;
      ferrors['name'] = true;
    } else if (!name.match(/^[a-zA-Z ]+$/)) {
      valid = false;
      ferrors['name'] = true;
    }
    if (email !== undefined) {
      let lastAtPos = email.lastIndexOf('@');
      let lastDotPos = email.lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
        valid = false;
        ferrors["email"] = true;
      }
    }
    setErrors(ferrors);
    return (valid);
  }
  const changeHandler = (field, value) => {
    setFields({ ...fields, [field]: value });
  }
  const submitHandler = (e) => {
    e.preventDefault();
    if (validationHandler()) {
      set(ref(database, '/users/' + phone), {
        phone: phone,
        name: fields.name,
        email: fields.email,
        date: Date()
      });
      navigate("/")
    } else {

    }
  }
  return (
    <form onSubmit={e => submitHandler(e)}>
      <div className='petition_main'>
        <div className='petition_title'>
          Petition
     </div>
        <div className='petition_content'>
          <p>
            <strong>Addressed To:  </strong>

            Honorable Supreme Court / President of India/ Prime Minister of India
            </p>
          <p>
            <strong>Mullaperiyar dam</strong> was built by the Britishers 116 years back in Kerala. A treaty was signed between the Madras state and king of Travancore to share water to TamilNadu. It was signed for a period of 999 years. According to the builder who built this, the life expectancy of dam was around 70 years and now it has crossed 116 years.
</p><p>
            A population of over one lakh between the dam and Idukki reservoir 40km below the dam, over 40 lakh people in the lower reaches of Periyar, Azhutha, Meenachil, Pamba and Manimalayar rivers– spread across five districts – will be drowned if there is a calamity. This would also destroy properties and agri fields.
</p>
          <p>
            This dam was constructed using Lime Surky concrete which is 6 times inferior than the present M30 concrete used for construction. Already a good portion of lime has been washed away. There is leakage at the surface and gallery of the Dam. A leakage is found in between the 17th and 18th block. The Legislative Sub Committee has examined the dam and found that there is leakage between 10th and 11th block of the dam. The quantity of the seepage water is increasing day by day. A severe damage was found in the masonry structure at 95 to 106 door from the base of the dam. Apart from innumerable patholes, crevices and other openings that are visible on the surface of the Dam, the masonry cover provided at the upstream portion of the Dam has undergone a crushing between 95 to 106 foot through the entire length of the Dam. The damage that the dam has undergone in between the above noted elevation is so severe that no rectification can save the Dam from a disaster. More importantly no technology has developed in the whole world to perfectly strengthen a dam which is old and deteriorating. Therefore the strengthening works done has actually weakened the dam.
</p>
          <p>
            <strong>Expected Solution: </strong> Decommission the old Mullaperiyar Dam
</p>
          <p>
            We humbly appeal to the <strong>Honorable Supreme Court / President of India/ Prime Minister of India </strong>to urgently interfere in this matter to rectify this by an order in consensus with the State of Kerala and the State of Tamil Nadu and ring back the safety of people of Kerala and water to our neighboring State.
</p>

        </div>

        <div className='petition_footer'>

          <label><input type="checkbox" checked disabled /> “I solemnly swear (or affirm) that I know the contents and purposes of this petition and that I signed only once and of my own free will.”  </label>


          <div className='submitsec'>
            <input type="text" placeholder="name" className={errors.name ? 'textval' : ''} onChange={e => changeHandler('name', e.target.value)} value={fields.name}></input>
            <input type="text" placeholder="e-mail" className={errors.email ? 'textval' : ''} onChange={e => changeHandler('email', e.target.value)} value={fields.email}></input>
            <button type="submit" className='submitbtn' disabled={isSubmitted}> Submit</button>
          </div>
        </div>
        <div>
          <br />

        </div>

      </div>
    </form>
  );
};

export default Petition;